.section-home-2
	@media (min-width: 768px)
		margin-top: r(30px)
	.swiper-container
		padding: r(30px) 0px
	.item-home-2
		display: flex
		flex-direction: column
		justify-content: space-between
		min-height: 400px
		border-radius: 30px
		background-image: linear-gradient(180deg, #ff0000 0%, #7d0000 100%)
		box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.1)
		padding: r(35px) r(30px) r(40px)
		@media (min-width: 768px)
			padding: r(65px) r(30px) r(40px)
		@media (min-width: 1024px)
			min-height: 420px
			padding: r(65px) r(30px) r(40px)
		@media (min-width: 1280px)
			min-height: 500px
			padding: r(85px) r(70px) r(60px)
		&.white
			background-image: linear-gradient(180deg, #fff 0%, #fff 100%)
			.wrap
				.title
					color: $main
				.desc
					color: #999999
			.btn-view-more
				background-color: $main
				span
					color: white
		.wrap
			.title
				color: #ffffff
				font-size: r(40px)
				font-weight: 700
				text-align: center
				text-transform: uppercase
				margin-bottom: r(40px)
				@media (max-width: 767.98px)
					margin-bottom: r(20px)
