.btn
	cursor: pointer
	user-select: none
	&.btn-gradient-1
		padding: 4px 10px
		border-radius: 15px
		border: 3px solid white
		height: 34px
		background-image: url('../img/background/phone_bg.png')
		background-position: center
		display: flex
		align-items: center
		justify-content: center
		box-shadow: 0px 3px 2px 0px rgba(12, 12, 12, .3)
		color: white
		em
			font-size: 18px
			margin-right: 4px
			display: flex
		span
			font-weight: 500
			white-space: nowrap
			font-size: 14px
	&.btn-view-more
		width: 160px
		height: 50px
		background-color: #ffffff
		display: flex
		align-items: center
		justify-content: center
		transition: .3s all  ease-in-out
		&:hover
			background-color: $main
			span
				color: white
		span
			color: $main
			transition: .3s all  ease-in-out