header
	position: fixed
	top: 0
	left: 0
	width: 100%
	z-index: 999
	background: linear-gradient(to right, #a0081d, #e02622)
	.container
		@media (max-width: 1023.98px)
			max-width: 100%
	nav
		height: 80px
		position: relative
		display: flex
		justify-content: space-between
		@media (max-width: 767.98px)
			height: 60px
		.menu-right
			height: 100%
			display: flex
			flex: 1
			justify-content: flex-end
			align-items: center
			.btn
				@media (max-width: 767.98px)
					display: none
			.btn-contact
				margin-left: 20px
		.menu-left
			display: flex
			height: 100%
			.logo
				width: 186px
				position: relative
				z-index: 99
				@media (max-width: 767.98px)
					width: 140px
				a
					position: absolute
					display: block
					top: 0
					width: 100%
					left: 0
			.list-menu
				@media (min-width: 1024px)
					height: 100%
					display: flex
					align-items: center
					padding-left: 28px
				@media (max-width: 1023.98px)
					position: fixed
					top: 80px
					right: 0
					height: 100vh
					background: linear-gradient(90deg,#a0081d,#e02622)
					width: 280px
					padding: 0 15px
					transform: translateX(100%)
					transition: .3s all ease-in-out
					opacity: 0
					will-change: transform
					@media (max-width: 575.98px)
						width: 100%
						padding-top: 60px
					&.isOpen
						opacity: 1
						transform: none
				@media (max-width: 767.98px)
					top: 60px
				ul
					+type-none
					@media (min-width: 1024px)
						display: flex
						align-items: center

					li
						&+li
							@media (min-width: 1024px)
								margin-left: 36px
						a
							font-size: r(14px)
							font-weight: bold
							text-transform: uppercase
							padding-bottom: 5px
							color: white
							+hover-underline(95%,white)
							@media (max-width: 1023.98px)
								font-size: r(16px)
								padding: 15px 20px
								display: block
								border-bottom: 1px solid #ffffff40
				.btn
					margin-top: 15px
