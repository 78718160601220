@font-face
	font-family: "Helvetica Neue"
	src: url('../fonts/HelveticaNeue.otf') format('OpenType')
	font-weight: 400
@font-face
	font-family: "Helvetica Neue"
	src: url('../fonts/HelveticaNeue-Light.otf') format('OpenType')
	font-weight: 300
@font-face
	font-family: "Helvetica Neue"
	src: url('../fonts/HelveticaNeue-Medium.otf') format('OpenType')
	font-weight: 500
@font-face
	font-family: "Helvetica Neue"
	src: url('../fonts/HelveticaNeue-Bold.otf') format('OpenType')
	font-weight: 700