.passwordrecovery
	.admin-content
		background-color: #fff
		padding: 60px 0px
		.wrap-secure
			margin: 0 auto
			box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06)
			padding: 15px
			border-radius: 20px
			max-width: 350px
			width: 100%
	.col-sm-9
		flex: 0 0 100%
		max-width: 100%
	.panel-heading
		*
			text-align: center
			color: $main
			font-size: r(36px)
	#ctl00_mainContent_PasswordRecovery1_UserNameContainerID_UserName
		border: 0
		outline: none
		border-bottom: 1px solid #eaeaea
		width: 100%
		padding: 5px 0px
	#ctl00_mainContent_PasswordRecovery1_UserNameContainerID_captcha_CaptchaTextBox
		border: 0
		outline: none
		border-bottom: 1px solid #eaeaea
		width: 100%
		padding: 5px 0px
	#ctl00_mainContent_PasswordRecovery1_UserNameContainerID_lblEnterUserName
		white-space: nowrap
		text-align: left
		margin-bottom: 5px
	#ctl00_mainContent_PasswordRecovery1_UserNameContainerID_SubmitButton
		width: 100%
		background-color: $main
		color: white
		margin-top: 10px
		outline: 0
		border: 0
		padding: 5px 0px
	#ctl00_mainContent_lblMailError
		font-weight: 400!important
		font-style: italic
		color: red
		font-family: 'Philosopher', sans-serif!important
		font-size: .9rem
		margin-top: 5px
		padding: 0 15px
	#ctl00_mainContent_PasswordRecovery1_UserNameContainerID_captcha_ctl00
		font-weight: 400!important
		font-style: italic
		color: red
		font-family: 'Philosopher', sans-serif!important
		font-size: .9rem
