#page-banner 
	width: 100%
	position: relative

	.banner__pages 
		width: 100%
		height: 49vh

		img 
			width: 100%
			height: 100%
			object-fit: cover
			object-position: center
		@media screen and ( max-width: 1024px)
			height: 30vh
