//remixi
@font-face
	font-family: "remixicon"
	src: url('../fonts/remixicon.eot')
	src: url("../fonts/remixicon.woff2") format("woff2")
	src: url("../fonts/remixicon.woff") format("woff")
	src: url('../fonts/remixicon.ttf') format('truetype')
	font-display: swap
[class^="ri-"], [class*=" ri-"]
	font-family: 'remixicon' !important
	font-style: normal
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
.ri-arrow-up-line
	&::before
		content: "\EA78"
.ri-subtract-line
	&::before
		content: "\f1af"
.ri-add-line
	&::before
		content: "\ea13"
.ri-play-circle-line
	&::before
		content: "\F009"
.ri-arrow-down-s-fill
	&::before
		content: "\EA4D"
.ri-close-fill
	&::before
		content: "\EB98"
.ri-checkbox-circle-fill
	&:before
		content: "\EB80"
.ri-arrow-down-s-line
	&::before
		content: "\EA4E"
.ri-arrow-left-s-fill
	&::before
		content: "\EA60"
.ri-arrow-right-s-fill
	&::before
		content: "\EA6C"
.ri-menu-line
	&::before
		content: "\EF3E"
.ri-home-3-line
	&::before
		content: "\EE1B"
.ri-align-left
	&::before
		content: "\EA27"
.ri-close-line
	&::before
		content: "\EB99"
//liner
@font-face
	font-family: 'Linearicons-Free'
	src: url('../fonts/Linearicons-Free.ttf') format('truetype')
	src: url('../fonts/Linearicons-Free.eot') format('embedded-opentype')
	src: url('../fonts/Linearicons-Free.woff2') format('woff2')
	src: url('../fonts/Linearicons-Free.woff') format('woff')
	font-weight: normal
	font-style: normal
	font-display: swap
.lnr
	font-family: 'Linearicons-Free'
	speak: none
	font-style: normal
	font-weight: normal
	font-variant: normal
	text-transform: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
.lnr-chevron-left
	&::before
		content: "\e875"
.lnr-chevron-right
	&::before
		content: "\e876"
.lnr-undo
	&::before
		content: "\e860"
.lnr-magnifier
	&::before
		content: '\e86f'
.lnr-bubble
	&::before
		content: '\e83f'
.lnr-envelope
	&::before
		content: '\e818'
		transform: translateY(-2px)
.lnr-phone-handset
	&::before
		content: '\e830'
.lnr-chevron-down
	&::before
		content: '\e874'
