footer
	z-index: 10
	position: relative
	margin-top: r(150px)
	width: 100%
	background-image: url('../img/background/footer-bg.jpg')
	background-size: cover
	background-position: top
	@media (max-width: 1023.98px)
		margin-top: r(100px)
	.logo
		position: relative
		margin-top: -38px
		max-width: 200px
		.logo-footer-ds
			position: absolute
			left: 0
			top: -23.5%
			z-index: -1
			width: 100%
	.line-bg
		height: 12px
		z-index: -1
		position: relative
		background-image: url('../img/background/fotter-line-sd.png')
	.title-footer
		margin-bottom: 14px
		font-size: 16px
		text-align: left
		font-weight: bold
		color: white
		font-family: "Helvetica Neue"
	.footer-info
		margin-top: 30px
		padding: 0 13px
		margin-bottom: r(36px)
		.row
			max-width: 1170px
		.col-lg-6
			@media (max-width: 767.98px)
				margin-bottom: r(30px)
		.list-info
			+type-none
			li
				position: relative
				color: white
				font-size: 13px
				font-family: "Helvetica Neue"
				font-weight: 400
				&+li
					margin-top: 8px
		.social-footer
			+type-none
			display: flex
			align-items: center
			li
				&+li
					margin-left: 11px
				a
					display: flex
					align-items: center
					justify-content: center
					border: 1px solid white
					border-radius: 50%
					width: 40px
					height: 40px
					transition: .3s all  ease-in-out
					&:hover
						background-color: #fff
						em
							color: $main
					em
						color: white
						transition: .3s all  ease-in-out
	.footer-copyright
		color: white
		font-size: 14px
		border-top: solid 1px #ef2c29
		text-align: center
		color: #ffffff
		padding: 10px
