
.menu.cross.menu--1.hambuger
	display: none
	@media (max-width: 1023.98px)
		flex: 0 0 44px
		height: 40px
		background: linear-gradient(to right, #a0081d, #e02622)
		display: flex
		align-items: center
		justify-content: center
		margin-left: 20px
		border: 3px solid white
		box-shadow: 0px 3px 2px 0px rgba(12, 12, 12, .3)
	.wrap
		display: block
		cursor: pointer
		position: absolute
		width: 100px
		height: 100px
	path
		fill: none
		stroke: #ffffff
		stroke-width: 4
		stroke-linecap: round
		stroke-linejoin: round
		--length: 24
		--offset: -38
		stroke-dasharray: var(--length) var(--total-length)
		stroke-dashoffset: var(--offset)
		transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1)
		-webkit-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1)
		-moz-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1)
		-ms-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1)
		-o-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1)
	&.active
		svg .line--1,
		svg .line--3
			--length: 22.627416998
			--offset: -93.7
		svg .line--2
			--length: 0
			--offset: -50
	.line--1, .line--3
		--total-length: 126.64183044433594
	.line--2
		--total-length: 70
// Hambuger html
	// .hambuger.menu.cross.menu--1
	// 	.wrap
	// 		svg(viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg')
	// 			path.line--1(d='M0 40h62c13 0 6 28-4 18L35 35')
	// 			path.line--2(d='M0 50h70')
	// 			path.line--3(d='M0 60h62c13 0 6-28-4-18L35 65')