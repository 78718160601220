.sec-home-1
	padding-bottom: 0
	.box-form-search
		min-height: 420px
		border-radius: 30px
		display: flex
		align-items: center
		justify-content: center
		position: relative
		z-index: 2
		padding: 28px 60px
		@media (max-width: 767.98px)
			padding: 28px 15px
	.box-search-code
		margin: 0 auto
		padding-bottom: 32px
		display: flex
		flex-direction: column
		justify-content: center
		height: 100%
		@media (min-width: 1280px)
			max-width: 400px
			transform: translateX(29px)
		.logo
			margin-bottom: r(27px)
		.wrap-form
			.input-code
				max-width: 400px
				margin: 0 auto
				text-align: center
				input
					width: 100%
					border: 0
					background-color: transparent
					text-align: center
					color: white
					padding-bottom: 8px
					border-bottom: 1px solid white
					&:focus::placeholder
						color: transparent
					&::placeholder
						color: white
			.button-search
				button
					width: 100px
					display: flex
					align-items: center
					justify-content: center
					height: 50px
					border: 1px solid #ffffff
					color: #ffffff
					font-size: 1rem
					margin: 0 auto
					margin-top: 20px
					background-color: transparent
					transition: .3s all ease-in-out
					&:hover
						background-color: #fff
						color: $main
	.box-tutorial-search
		padding-left: r(30px)
		@media (min-width: 1280px)
			padding-left: 86px
		.title
			color: #ffffff
			font-size: r(36px)
			margin-bottom: r(25px)
			font-weight: 700
			@media (max-width: 575.98px)
				font-size: r(30px)
				margin-bottom: r(15px)
		.content
			table
				tr
					td
						color: #ffffff
						font-size: r(18px)
						padding-bottom: r(25px)
						@media (max-width: 767.98px)
							padding-bottom: r(15px)
						&:first-child
							font-weight: 700
							vertical-align: top
							white-space: nowrap
						&:last-child
							padding-left: r(60px)
							@media (max-width: 575.98px)
								font-size: 14px
								padding-left: r(30px)
	.box-result-search
		box-shadow: 6px 6px 30px rgba(0, 0, 0, 0.1)
		background-color: #ffffff
		transform: translateY(-30px)
		padding: r(60px) 0px
		@media (max-width: 1023.98px)
			padding: 60px r(30px)
		.result
			max-width: 810px
			margin: 0 auto
