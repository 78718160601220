.banner-home
	.swiper-container 
		width: 100%
    	height: 100%
	.swiper-slide 
		text-align: center
		font-size: 18px
		background: #fff
		display: -webkit-box
		display: -ms-flexbox
		display: -webkit-flex
		display: flex
		-webkit-box-pack: center
		-ms-flex-pack: center
		-webkit-justify-content: center
		justify-content: center
		-webkit-box-align: center
		-ms-flex-align: center
		-webkit-align-items: center
		align-items: center
	.box-banner
		position: relative
		height: 67vh
		.img 
			height: 100%
			img 
				height: 100% 
				width: 100%
				object-fit: cover 
	.box-text
		position: absolute
		top: 50%
		left: 18%
		transform: translateY(-50%)
		@media screen and ( max-width: 768px )
			left: 5%
		h2 
			font-size: 48 / 16 * 1rem
			font-weight: 800
			text-transform: uppercase
			color: white
			text-align: left
			line-height: 1
			span 
				font-size: 30 / 16 * 1rem
