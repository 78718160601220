body {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }

@media (min-width: 576px) {
  .container {
    max-width: 540px;
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 1024px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1280px) {
  .container {
    max-width: 1260px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }

.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.no-gutters > *,
.no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0; }

[class*="col-"] {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1024px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1280px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1280px) {
  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: rgba(43, 43, 43, 0.08); }
  ::-webkit-scrollbar {
    width: 6px;
    height: 2px; }
  ::-webkit-scrollbar-thumb {
    background: #9a9a9a;
    border-radius: 3px; } }

::selection {
  background: #a0081d;
  color: white; }

html {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

main {
  padding-top: 8.125rem; }
  @media (min-width: 768px) {
    main {
      padding-top: 9.375rem; } }

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  background-color: #f5f5f5;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  background-image: url("../img/background/bg-world.png");
  background-position-y: 100px; }

body, html {
  font-size: 14px; }
  @media (min-width: 1024px) {
    body, html {
      font-size: 15px; } }
  @media (min-width: 1280px) {
    body, html {
      font-size: 16px; } }

* {
  box-sizing: border-box; }
  *::before, *::after {
    box-sizing: border-box; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0;
  margin-left: 0; }

blockquote {
  margin: 0; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  text-decoration: none;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  color: inherit; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

button:focus {
  outline: none;
  outline: 5px auto -webkit-focus-ring-color; }

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

select {
  word-wrap: normal; }

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden], .d-none, .hidden {
  display: none !important; }

input:focus, button:focus, a:focus, .button-prev:focus, .button-next:focus, textarea:focus, select:focus {
  outline: none; }

img {
  max-width: 100%;
  height: auto; }

.zoom-img {
  overflow: hidden; }
  .zoom-img:hover img {
    transform: scale(1.05) translateZ(0); }
  .zoom-img img {
    transition: .3s all ease-in-out; }

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(40px); }
  to {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes fade-in2 {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(20px); }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0px); } }

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(45px);
    filter: blur(5); }
  100% {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0); } }

@keyframes blurText {
  0% {
    filter: blur(2px); }
  100% {
    filter: blur(2px); } }

@keyframes loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes loading1-circle {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -600; } }

#load {
  width: 50px;
  display: inline-block;
  animation: loading 2s linear infinite; }

#loading-inner {
  stroke-dashoffset: 1;
  stroke-dasharray: 300;
  stroke-width: 10;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  animation: loading1-circle 2s linear infinite;
  stroke: #a0081d;
  fill: transparent; }

@keyframes AlertError {
  0% {
    transform: translateX(30px); }
  20% {
    transform: translateX(-30px); }
  40% {
    transform: translateX(15px); }
  60% {
    transform: translateX(-15px); }
  80% {
    transform: translateX(8px); }
  100% {
    transform: translateX(0px); } }

.w-100 {
  width: 100%; }

.mx-auto {
  margin: 0 auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.d-flex {
  display: flex; }

.flcc {
  display: flex;
  flex-direction: column; }

.flc {
  flex-direction: column; }

.flex-row {
  flex-direction: row; }

.flex-wrap {
  flex-wrap: wrap; }

.jcb {
  justify-content: space-between; }

.jcc {
  justify-content: center; }

.ovh {
  overflow: hidden; }

.jcs {
  justify-content: flex-start; }

.jce {
  justify-content: flex-end; }

.aic {
  align-items: center; }

.relative {
  position: relative; }

.ais {
  align-items: flex-start; }

.aie {
  align-items: flex-end; }

.tcc {
  text-align: center; }

.tcr {
  text-align: right; }

.flex-wrap {
  flex-wrap: wrap; }

.button-prev, .button-next {
  cursor: pointer; }

.line-bottom {
  border-bottom: 1px solid rgba(160, 8, 29, 0.28); }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-center {
  text-align: center; }

.color-white {
  color: white; }

.color-gray {
  color: #999999; }

.color-text {
  color: #333333; }

.color-main {
  color: #a0081d; }

.f-12 {
  font-size: 0.75rem; }

.f-14 {
  font-size: 0.875rem; }

.f-16 {
  font-size: 1rem; }

.f-18 {
  font-size: 1.125rem; }

.f-20 {
  font-size: 1.25rem; }

.f-22 {
  font-size: 1.375rem; }

.f-24 {
  font-size: 1.5rem; }

.f-25 {
  font-size: 1.5625rem; }

.f-30 {
  font-size: 1.875rem; }

.f-36 {
  font-size: 2.25rem; }

.f-38 {
  font-size: 2.375rem; }

.f-40 {
  font-size: 2.5rem; }

.f-60 {
  font-size: 3.75rem; }

.fw-300 {
  font-weight: 300; }

.fw-400 {
  font-weight: 400; }

.fw-500 {
  font-weight: 500; }

.fw-600 {
  font-weight: 600; }

.fw-700 {
  font-weight: 700; }

.fw-800 {
  font-weight: 800; }

.fw-900 {
  font-weight: 900; }

.line-30 {
  line-height: 30px; }

.p-0 {
  padding: 0; }

.pl-0 {
  padding-left: 0; }

.pt-1 {
  padding-top: 1rem; }

.pt-2 {
  padding-top: 2rem; }

.pt-3 {
  padding-top: 3rem; }

.pr-0 {
  padding-right: 0; }

.m-0 {
  margin: 0; }

.mb-40 {
  margin-bottom: 2.5rem; }

.mb-30 {
  margin-bottom: 1.875rem; }

.text-left {
  text-align: left; }

.transition {
  transition: all 1s cubic-bezier(0.22, 0.44, 0, 1); }

.type-none {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.mh-100 {
  min-height: 100vh; }

.ovh {
  overflow: hidden; }

.tab-overlow {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .tab-overlow li:first-child {
    margin-left: auto; }
  .tab-overlow li:last-child {
    margin-right: auto; }

.btn {
  cursor: pointer;
  user-select: none; }
  .btn.btn-gradient-1 {
    padding: 4px 10px;
    border-radius: 15px;
    border: 3px solid white;
    height: 34px;
    background-image: url("../img/background/phone_bg.png");
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 2px 0px rgba(12, 12, 12, 0.3);
    color: white; }
    .btn.btn-gradient-1 em {
      font-size: 18px;
      margin-right: 4px;
      display: flex; }
    .btn.btn-gradient-1 span {
      font-weight: 500;
      white-space: nowrap;
      font-size: 14px; }
  .btn.btn-view-more {
    width: 160px;
    height: 50px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s all  ease-in-out; }
    .btn.btn-view-more:hover {
      background-color: #a0081d; }
      .btn.btn-view-more:hover span {
        color: white; }
    .btn.btn-view-more span {
      color: #a0081d;
      transition: .3s all  ease-in-out; }

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeue.otf") format("OpenType");
  font-weight: 400; }

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeue-Light.otf") format("OpenType");
  font-weight: 300; }

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeue-Medium.otf") format("OpenType");
  font-weight: 500; }

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeue-Bold.otf") format("OpenType");
  font-weight: 700; }

.menu.cross.menu--1.hambuger {
  display: none; }
  @media (max-width: 1023.98px) {
    .menu.cross.menu--1.hambuger {
      flex: 0 0 44px;
      height: 40px;
      background: linear-gradient(to right, #a0081d, #e02622);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      border: 3px solid white;
      box-shadow: 0px 3px 2px 0px rgba(12, 12, 12, 0.3); } }
  .menu.cross.menu--1.hambuger .wrap {
    display: block;
    cursor: pointer;
    position: absolute;
    width: 100px;
    height: 100px; }
  .menu.cross.menu--1.hambuger path {
    fill: none;
    stroke: #ffffff;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    --length: 24;
    --offset: -38;
    stroke-dasharray: var(--length) var(--total-length);
    stroke-dashoffset: var(--offset);
    transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
    -ms-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1); }
  .menu.cross.menu--1.hambuger.active svg .line--1,
  .menu.cross.menu--1.hambuger.active svg .line--3 {
    --length: 22.627416998;
    --offset: -93.7; }
  .menu.cross.menu--1.hambuger.active svg .line--2 {
    --length: 0;
    --offset: -50; }
  .menu.cross.menu--1.hambuger .line--1, .menu.cross.menu--1.hambuger .line--3 {
    --total-length: 126.64183044433594; }
  .menu.cross.menu--1.hambuger .line--2 {
    --total-length: 70; }

@font-face {
  font-family: "remixicon";
  src: url("../fonts/remixicon.eot");
  src: url("../fonts/remixicon.woff2") format("woff2");
  src: url("../fonts/remixicon.woff") format("woff");
  src: url("../fonts/remixicon.ttf") format("truetype");
  font-display: swap; }

[class^="ri-"], [class*=" ri-"] {
  font-family: 'remixicon' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ri-arrow-up-line::before {
  content: "\EA78"; }

.ri-subtract-line::before {
  content: "\f1af"; }

.ri-add-line::before {
  content: "\ea13"; }

.ri-play-circle-line::before {
  content: "\F009"; }

.ri-arrow-down-s-fill::before {
  content: "\EA4D"; }

.ri-close-fill::before {
  content: "\EB98"; }

.ri-checkbox-circle-fill:before {
  content: "\EB80"; }

.ri-arrow-down-s-line::before {
  content: "\EA4E"; }

.ri-arrow-left-s-fill::before {
  content: "\EA60"; }

.ri-arrow-right-s-fill::before {
  content: "\EA6C"; }

.ri-menu-line::before {
  content: "\EF3E"; }

.ri-home-3-line::before {
  content: "\EE1B"; }

.ri-align-left::before {
  content: "\EA27"; }

.ri-close-line::before {
  content: "\EB99"; }

@font-face {
  font-family: 'Linearicons-Free';
  src: url("../fonts/Linearicons-Free.ttf") format("truetype");
  src: url("../fonts/Linearicons-Free.eot") format("embedded-opentype");
  src: url("../fonts/Linearicons-Free.woff2") format("woff2");
  src: url("../fonts/Linearicons-Free.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

.lnr {
  font-family: 'Linearicons-Free';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lnr-chevron-left::before {
  content: "\e875"; }

.lnr-chevron-right::before {
  content: "\e876"; }

.lnr-undo::before {
  content: "\e860"; }

.lnr-magnifier::before {
  content: '\e86f'; }

.lnr-bubble::before {
  content: '\e83f'; }

.lnr-envelope::before {
  content: '\e818';
  transform: translateY(-2px); }

.lnr-phone-handset::before {
  content: '\e830'; }

.lnr-chevron-down::before {
  content: '\e874'; }

.main-section {
  padding: 35px 0; }
  @media (min-width: 768px) {
    .main-section {
      padding: 40px 0; } }
  @media (min-width: 1024px) {
    .main-section {
      padding: 45px 0; } }
  @media (min-width: 1200px) {
    .main-section {
      padding: 60px 0; } }

.swiper-button-disabled {
  opacity: 0.4 !important; }

.wrap-button-slide {
  display: flex;
  align-items: center;
  justify-content: center; }
  .wrap-button-slide.none-static .btn-prev, .wrap-button-slide.none-static .btn-next {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%); }
  .wrap-button-slide .btn-prev, .wrap-button-slide .btn-next {
    z-index: 9;
    position: static; }
    @media (min-width: 1024px) {
      .wrap-button-slide .btn-prev, .wrap-button-slide .btn-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
  .wrap-button-slide .btn-prev {
    right: 100%; }
    @media (max-width: 1400px) {
      .wrap-button-slide .btn-prev {
        right: 95%; } }
    .wrap-button-slide .btn-prev.btn-arrow {
      transform: rotate(180deg) translateY(1px);
      transform-origin: center; }
      @media (min-width: 1024px) {
        .wrap-button-slide .btn-prev.btn-arrow {
          transform-origin: top;
          transform: rotate(180deg) translateY(-50%); } }
  .wrap-button-slide .btn-next {
    left: 100%; }
    @media (max-width: 1400px) {
      .wrap-button-slide .btn-next {
        left: 95%; } }

.banner-home .swiper-container {
  width: 100%;
    width-height: 100%; }

.banner-home .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }

.banner-home .box-banner {
  position: relative;
  height: 67vh; }
  .banner-home .box-banner .img {
    height: 100%; }
    .banner-home .box-banner .img img {
      height: 100%;
      width: 100%;
      object-fit: cover; }

.banner-home .box-text {
  position: absolute;
  top: 50%;
  left: 18%;
  transform: translateY(-50%); }
  @media screen and (max-width: 768px) {
    .banner-home .box-text {
      left: 5%; } }
  .banner-home .box-text h2 {
    font-size: 3rem;
    font-weight: 800;
    text-transform: uppercase;
    color: white;
    text-align: left;
    line-height: 1; }
    .banner-home .box-text h2 span {
      font-size: 1.875rem; }

#page-banner {
  width: 100%;
  position: relative; }
  #page-banner .banner__pages {
    width: 100%;
    height: 49vh; }
    #page-banner .banner__pages img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
    @media screen and (max-width: 1024px) {
      #page-banner .banner__pages {
        height: 30vh; } }

.global-breadcrumb {
  width: 100%;
  background-color: white;
  border-bottom: solid 1px #e1e1e1; }
  .global-breadcrumb .breadcrumb {
    display: flex;
    align-items: center;
    background-color: transparent;
    flex-wrap: wrap;
    padding: 10px 0;
    list-style: none;
    margin-bottom: 0; }
    .global-breadcrumb .breadcrumb li {
      position: relative;
      padding: 0 10px;
      display: flex;
      align-items: center; }
      .global-breadcrumb .breadcrumb li a {
        font-size: 14px;
        color: #666666;
        font-weight: 500;
        text-decoration: none; }
        @media (max-width: 991.98px) {
          .global-breadcrumb .breadcrumb li a {
            font-size: 10px; } }
      .global-breadcrumb .breadcrumb li.active a {
        color: #ddd; }
      .global-breadcrumb .breadcrumb li:hover a {
        color: #ddd; }
      .global-breadcrumb .breadcrumb li:first-child {
        padding-left: 0; }
        .global-breadcrumb .breadcrumb li:first-child a {
          font-size: 0; }
          .global-breadcrumb .breadcrumb li:first-child a::after {
            font-family: 'remixicon';
            font-size: 16px;
            content: "\EE1C";
            color: #999999; }
    .global-breadcrumb .breadcrumb li + li::before {
      position: absolute;
      top: 50%;
      left: -7px;
      transform: translateY(-50%);
      font-family: Material Icons;
      font-size: 18px;
      content: "keyboard_arrow_right";
      color: #999999; }
  @media (min-width: 768.98px) {
    .global-breadcrumb {
      margin-bottom: 0; } }
  @media (min-width: 320px) {
    .global-breadcrumb {
      margin-bottom: 0; } }

.wrap-form span.fa-exclamation-triangle {
  font-size: 0.875rem;
  color: red; }
  .wrap-form span.fa-exclamation-triangle::before {
    margin-right: 5px; }

.wrap-form .form-row {
  display: flex;
  margin: 0 -15px; }
  @media screen and (max-width: 768px) {
    .wrap-form .form-row {
      flex-wrap: wrap; } }
  .wrap-form .form-row .form-group {
    flex: 0 0 50%;
    padding: 0 15px; }
    @media screen and (max-width: 768px) {
      .wrap-form .form-row .form-group {
        flex: 0 0 100%; } }
    .wrap-form .form-row .form-group input[type="text"] {
      width: 100%; }

span.fa-exclamation-triangle {
  font-weight: 400 !important;
  font-style: italic;
  color: red;
  font-family: "Philosopher", sans-serif !important;
  font-size: .9rem; }
  span.fa-exclamation-triangle::before {
    display: none !important; }

.frm-btn-reset {
  display: none !important; }

.frm-captcha {
  display: flex;
  float: left;
  width: calc(100% - 233px);
  padding-right: 20px; }
  @media screen and (max-width: 1280px) {
    .frm-captcha {
      width: calc(100% - 110px); } }
  @media screen and (max-width: 1023.67px) {
    .frm-captcha {
      float: none;
      padding-right: 0;
      flex: 1;
      width: 100%; } }
  @media screen and (max-width: 767.67px) {
    .frm-captcha {
      flex-direction: column; } }
  .frm-captcha .label {
    display: none !important; }
  .frm-captcha .frm-captcha-input {
    order: 2;
    flex: 1; }
    @media screen and (max-width: 767.67px) {
      .frm-captcha .frm-captcha-input {
        order: 1;
        margin-bottom: 20px; } }
  .frm-captcha .RadCaptcha_Default {
    position: relative; }
    @media screen and (max-width: 767.67px) {
      .frm-captcha .RadCaptcha_Default {
        flex: 0 0 100%;
        order: 2; } }
  .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl00 {
    position: absolute;
    top: 120%;
    color: red; }
  .frm-captcha #ctl00_mainContent_ctl02_txtCaptcha {
    width: 100%;
    height: 40px;
    margin-top: 0;
    border: 1px solid #e1e1e1;
    background-color: #fefefe; }
    @media screen and (max-width: 767.67px) {
      .frm-captcha #ctl00_mainContent_ctl02_txtCaptcha {
        width: 100%; } }
  .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 {
    display: flex;
    align-items: center; }
    .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 img {
      width: 100% !important;
      height: 40px !important; }
    .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 .rcRefreshImage {
      font-size: 0;
      margin-left: 5px;
      height: auto !important;
      margin-right: 10px; }
      .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 .rcRefreshImage::after {
        display: none !important; }
      .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 .rcRefreshImage::before {
        content: "\e861";
        font-family: Linearicons-Free;
        color: #333;
        font-size: 30px;
        font-weight: 400; }

.subscribefrm .fa-exclamation-triangle {
  top: 110% !important;
  right: 0 !important;
  width: auto !important;
  text-indent: 0 !important; }
  .subscribefrm .fa-exclamation-triangle::after {
    display: none; }

.signup-page {
  background-color: #fff;
  padding: 60px 0px; }
  .signup-page .signup-panel {
    margin: 0 auto;
    box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06);
    padding: 15px;
    border-radius: 20px;
    max-width: 450px;
    width: 100%; }
  .signup-page .col-xs-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important; }
  .signup-page .headtitle {
    font-size: 2.25rem;
    color: #a0081d;
    margin-bottom: 15px;
    text-align: center; }
  .signup-page label {
    font-size: 1.125rem; }
  .signup-page input[type="text"], .signup-page input[type="password"] {
    padding: 5px !important;
    height: 35px !important;
    border: 0 !important;
    outline: 0 !important;
    width: 100% !important;
    border-bottom: 1px solid #eaeaea !important;
    margin-bottom: 5px !important; }
  .signup-page select {
    padding: 5px !important;
    height: 35px !important;
    border: 0 !important;
    outline: 0 !important;
    width: 100% !important;
    border-bottom: 1px solid #eaeaea !important;
    margin-bottom: 5px !important; }
  .signup-page .form-group {
    margin-bottom: 15px; }
  .signup-page #ctl00_mainContent_RegisterUser___CustomNav0_StepNextButtonButton {
    width: 100%;
    background-color: #a0081d;
    color: white;
    margin-top: 10px;
    outline: 0;
    border: 0;
    padding: 5px 0px; }

.login-panel {
  background-color: #fff;
  margin: 0 auto;
  box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06);
  padding: 30px;
  border-radius: 20px; }
  .login-panel .headtitle {
    font-size: 2.25rem;
    text-align: center;
    color: #a0081d; }
  .login-panel .panel-heading {
    text-align: center;
    margin-bottom: 20px; }
  .login-panel .btn-login input {
    width: 100%;
    background-color: #a0081d;
    color: white;
    font-size: 1.125rem;
    padding: 5px 0px;
    border: 0;
    outline: none;
    margin: 9px 0px;
    transition: .3s all ease-in-out; }
    .login-panel .btn-login input:hover {
      background-color: red; }
  .login-panel .form-group {
    display: flex;
    width: 100%; }
    .login-panel .form-group.forget {
      display: flex;
      align-items: center; }
      .login-panel .form-group.forget input {
        margin-right: 10px; }
    .login-panel .form-group:nth-child(1), .login-panel .form-group:nth-child(2) {
      border-bottom: 1px solid #eaeaea;
      margin-bottom: 10px;
      align-items: center;
      padding: 5px 0px; }
      .login-panel .form-group:nth-child(1) label i, .login-panel .form-group:nth-child(2) label i {
        color: #a0081d; }
      .login-panel .form-group:nth-child(1) input, .login-panel .form-group:nth-child(2) input {
        width: 100%;
        flex: 0 0 100%;
        border: 0;
        padding-left: 15px;
        outline: 0; }
    .login-panel .form-group:nth-child(5) {
      display: flex;
      justify-content: space-between; }
      .login-panel .form-group:nth-child(5) a {
        transition: .3s all ease-in-out; }
        .login-panel .form-group:nth-child(5) a:hover {
          color: #a0081d; }
  .login-panel .panel-primary {
    width: 100%; }

.login-page {
  background-color: #fff;
  padding: 40px 0px; }

.loginpage main {
  padding-top: 90px; }

.loginpage .col-xs-12 {
  margin: 0 auto; }

.passwordrecovery .admin-content {
  background-color: #fff;
  padding: 60px 0px; }
  .passwordrecovery .admin-content .wrap-secure {
    margin: 0 auto;
    box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06);
    padding: 15px;
    border-radius: 20px;
    max-width: 350px;
    width: 100%; }

.passwordrecovery .col-sm-9 {
  flex: 0 0 100%;
  max-width: 100%; }

.passwordrecovery .panel-heading * {
  text-align: center;
  color: #a0081d;
  font-size: 2.25rem; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_UserName {
  border: 0;
  outline: none;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  padding: 5px 0px; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_captcha_CaptchaTextBox {
  border: 0;
  outline: none;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  padding: 5px 0px; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_lblEnterUserName {
  white-space: nowrap;
  text-align: left;
  margin-bottom: 5px; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_SubmitButton {
  width: 100%;
  background-color: #a0081d;
  color: white;
  margin-top: 10px;
  outline: 0;
  border: 0;
  padding: 5px 0px; }

.passwordrecovery #ctl00_mainContent_lblMailError {
  font-weight: 400 !important;
  font-style: italic;
  color: red;
  font-family: "Philosopher", sans-serif !important;
  font-size: .9rem;
  margin-top: 5px;
  padding: 0 15px; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_captcha_ctl00 {
  font-weight: 400 !important;
  font-style: italic;
  color: red;
  font-family: "Philosopher", sans-serif !important;
  font-size: .9rem; }

.search-page {
  padding: 40px 0; }
  .search-page h1 {
    letter-spacing: -2px;
    margin-bottom: 20px;
    font-size: 45px;
    line-height: 55px;
    font-weight: 500;
    color: #333333; }
    @media (max-width: 1024px) {
      .search-page h1 {
        font-size: 24px;
        line-height: 30px; } }
    .search-page h1.center {
      text-align: center; }
  .search-page .btn-reindex {
    display: none; }
  .search-page .searchcontrols .form-inline {
    position: relative; }
    .search-page .searchcontrols .form-inline:before {
      position: absolute;
      content: '\f349';
      font-family: "Material Design Icons";
      top: 0;
      right: 10px;
      z-index: 1;
      font-size: 24px;
      color: #4285f4;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .search-page .searchcontrols .form-inline .form-group:nth-child(2), .search-page .searchcontrols .form-inline .form-group:nth-child(3) {
      display: none; }
    .search-page .searchcontrols .form-inline .form-group:nth-child(4) {
      position: absolute;
      top: 0;
      right: 5px;
      z-index: 11; }
      .search-page .searchcontrols .form-inline .form-group:nth-child(4)::before {
        content: "\F0CD";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-family: remixicon !important;
        color: #a0081d; }
  .search-page .searchcontrols .frm-btn {
    position: absolute;
    top: 0;
    right: 10px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2; }
  .search-page input[type="text"] {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 54px;
    border: 1px solid #dfe1e5;
    background-color: #fff;
    color: #333;
    font-size: .875rem;
    font-weight: 400;
    height: 44px;
    border-radius: 22px;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none; }
    .search-page input[type="text"]:focus {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      border-color: rgba(223, 225, 229, 0); }
  .search-page input[type="submit"] {
    border-radius: 2px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    outline: none;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    font-size: 0; }
  .search-page .text-danger {
    margin-top: 20px;
    font-size: 15px;
    color: #333;
    font-style: italic;
    font-weight: 600; }
  .search-page .searchresultsummary {
    margin-bottom: 20px; }
  .search-page .searchresults {
    margin-top: 30px; }
    .search-page .searchresults .modulepager:first-child {
      display: none; }
  .search-page .searchresultlist {
    margin-bottom: 20px; }
  .search-page .searchresult {
    margin-bottom: 30px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    padding: 16px;
    border-radius: 8px; }
    .search-page .searchresult:last-child {
      margin-bottom: 0; }
    .search-page .searchresult h3 {
      font-size: 18px;
      line-height: 1.33;
      font-weight: 400;
      margin-bottom: 10px; }
      .search-page .searchresult h3 a {
        color: #1A0DAB;
        text-decoration: none;
        font-weight: 500; }
        .search-page .searchresult h3 a:hover {
          text-decoration: underline; }
    .search-page .searchresult .searchresultdesc {
      color: #545454;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small;
      font-weight: 400;
      margin-bottom: 10px; }
    .search-page .searchresult .searchterm {
      color: #6a6a6a;
      font-weight: bold;
      font-style: normal;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small; }
    .search-page .searchresult hr {
      display: none !important; }

.wrap-search-main {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10px 0px;
  padding-bottom: 15px;
  position: fixed;
  width: 100%;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: .4s all ease-in-out;
  z-index: 99;
  box-shadow: 1px 5px 15px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  height: 100%; }
  .wrap-search-main.active {
    opacity: 1;
    visibility: visible; }
    .wrap-search-main.active .close {
      top: 100px;
      opacity: 1; }
    .wrap-search-main.active .searchbox button em {
      transform: translateY(0px);
      opacity: 1;
      transition-delay: .5s; }
    .wrap-search-main.active .searchbox input {
      transform: translateX(0px);
      opacity: 1; }
    .wrap-search-main.active .searchbox::before {
      width: 100%;
      opacity: 1; }
  .wrap-search-main .close {
    position: absolute;
    top: 0px;
    right: 20px;
    display: flex;
    cursor: pointer;
    opacity: 0;
    transition: .3s all ease-in-out;
    transition-delay: .4s; }
    .wrap-search-main .close em {
      color: white;
      font-size: 40px; }
  .wrap-search-main .searchbox {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%; }
    .wrap-search-main .searchbox [class*="Module"] {
      width: 100%; }
    .wrap-search-main .searchbox::before {
      content: '';
      width: 0%;
      opacity: 0;
      height: 1px;
      position: absolute;
      left: 0;
      top: calc( 100% + 5px);
      background-color: #fff;
      transition-delay: .1s;
      transition: .75s all ease-in-out; }
    .wrap-search-main .searchbox input {
      flex: 1;
      border: 0;
      background-color: transparent;
      color: white;
      transition: .6s all ease-in-out;
      transition-delay: .2s;
      transform: translateX(-30px);
      opacity: 0; }
      .wrap-search-main .searchbox input::placeholder {
        color: white; }
    .wrap-search-main .searchbox button {
      outline: none;
      border: 0;
      background-color: transparent;
      display: flex;
      padding: 0; }
      .wrap-search-main .searchbox button em {
        color: white;
        opacity: 0;
        transform: translateY(-20px);
        transition: .3s all ease-in-out; }

.sec-home-1 {
  padding-bottom: 0; }
  .sec-home-1 .box-form-search {
    min-height: 420px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    padding: 28px 60px; }
    @media (max-width: 767.98px) {
      .sec-home-1 .box-form-search {
        padding: 28px 15px; } }
  .sec-home-1 .box-search-code {
    margin: 0 auto;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    @media (min-width: 1280px) {
      .sec-home-1 .box-search-code {
        max-width: 400px;
        transform: translateX(29px); } }
    .sec-home-1 .box-search-code .logo {
      margin-bottom: 1.6875rem; }
    .sec-home-1 .box-search-code .wrap-form .input-code {
      max-width: 400px;
      margin: 0 auto;
      text-align: center; }
      .sec-home-1 .box-search-code .wrap-form .input-code input {
        width: 100%;
        border: 0;
        background-color: transparent;
        text-align: center;
        color: white;
        padding-bottom: 8px;
        border-bottom: 1px solid white; }
        .sec-home-1 .box-search-code .wrap-form .input-code input:focus::placeholder {
          color: transparent; }
        .sec-home-1 .box-search-code .wrap-form .input-code input::placeholder {
          color: white; }
    .sec-home-1 .box-search-code .wrap-form .button-search button {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      border: 1px solid #ffffff;
      color: #ffffff;
      font-size: 1rem;
      margin: 0 auto;
      margin-top: 20px;
      background-color: transparent;
      transition: .3s all ease-in-out; }
      .sec-home-1 .box-search-code .wrap-form .button-search button:hover {
        background-color: #fff;
        color: #a0081d; }
  .sec-home-1 .box-tutorial-search {
    padding-left: 1.875rem; }
    @media (min-width: 1280px) {
      .sec-home-1 .box-tutorial-search {
        padding-left: 86px; } }
    .sec-home-1 .box-tutorial-search .title {
      color: #ffffff;
      font-size: 2.25rem;
      margin-bottom: 1.5625rem;
      font-weight: 700; }
      @media (max-width: 575.98px) {
        .sec-home-1 .box-tutorial-search .title {
          font-size: 1.875rem;
          margin-bottom: 0.9375rem; } }
    .sec-home-1 .box-tutorial-search .content table tr td {
      color: #ffffff;
      font-size: 1.125rem;
      padding-bottom: 1.5625rem; }
      @media (max-width: 767.98px) {
        .sec-home-1 .box-tutorial-search .content table tr td {
          padding-bottom: 0.9375rem; } }
      .sec-home-1 .box-tutorial-search .content table tr td:first-child {
        font-weight: 700;
        vertical-align: top;
        white-space: nowrap; }
      .sec-home-1 .box-tutorial-search .content table tr td:last-child {
        padding-left: 3.75rem; }
        @media (max-width: 575.98px) {
          .sec-home-1 .box-tutorial-search .content table tr td:last-child {
            font-size: 14px;
            padding-left: 1.875rem; } }
  .sec-home-1 .box-result-search {
    box-shadow: 6px 6px 30px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    transform: translateY(-30px);
    padding: 3.75rem 0px; }
    @media (max-width: 1023.98px) {
      .sec-home-1 .box-result-search {
        padding: 60px 1.875rem; } }
    .sec-home-1 .box-result-search .result {
      max-width: 810px;
      margin: 0 auto; }

@media (min-width: 768px) {
  .section-home-2 {
    margin-top: 1.875rem; } }

.section-home-2 .swiper-container {
  padding: 1.875rem 0px; }

.section-home-2 .item-home-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
  border-radius: 30px;
  background-image: linear-gradient(180deg, #ff0000 0%, #7d0000 100%);
  box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.1);
  padding: 2.1875rem 1.875rem 2.5rem; }
  @media (min-width: 768px) {
    .section-home-2 .item-home-2 {
      padding: 4.0625rem 1.875rem 2.5rem; } }
  @media (min-width: 1024px) {
    .section-home-2 .item-home-2 {
      min-height: 420px;
      padding: 4.0625rem 1.875rem 2.5rem; } }
  @media (min-width: 1280px) {
    .section-home-2 .item-home-2 {
      min-height: 500px;
      padding: 5.3125rem 4.375rem 3.75rem; } }
  .section-home-2 .item-home-2.white {
    background-image: linear-gradient(180deg, #fff 0%, #fff 100%); }
    .section-home-2 .item-home-2.white .wrap .title {
      color: #a0081d; }
    .section-home-2 .item-home-2.white .wrap .desc {
      color: #999999; }
    .section-home-2 .item-home-2.white .btn-view-more {
      background-color: #a0081d; }
      .section-home-2 .item-home-2.white .btn-view-more span {
        color: white; }
  .section-home-2 .item-home-2 .wrap .title {
    color: #ffffff;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2.5rem; }
    @media (max-width: 767.98px) {
      .section-home-2 .item-home-2 .wrap .title {
        margin-bottom: 1.25rem; } }

@media (max-width: 768.67px) {
  .number-tab-wrap {
    display: flex; }
    .number-tab-wrap .tabslet-tab {
      flex: 1; }
    .number-tab-wrap .icon-next {
      flex: 0 0 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
      border: 1px solid #a0081d; }
      .number-tab-wrap .icon-next .next {
        margin-top: 5px; }
      .number-tab-wrap .icon-next em {
        color: #a0081d; } }

footer {
  z-index: 10;
  position: relative;
  margin-top: 9.375rem;
  width: 100%;
  background-image: url("../img/background/footer-bg.jpg");
  background-size: cover;
  background-position: top; }
  @media (max-width: 1023.98px) {
    footer {
      margin-top: 6.25rem; } }
  footer .logo {
    position: relative;
    margin-top: -38px;
    max-width: 200px; }
    footer .logo .logo-footer-ds {
      position: absolute;
      left: 0;
      top: -23.5%;
      z-index: -1;
      width: 100%; }
  footer .line-bg {
    height: 12px;
    z-index: -1;
    position: relative;
    background-image: url("../img/background/fotter-line-sd.png"); }
  footer .title-footer {
    margin-bottom: 14px;
    font-size: 16px;
    text-align: left;
    font-weight: bold;
    color: white;
    font-family: "Helvetica Neue"; }
  footer .footer-info {
    margin-top: 30px;
    padding: 0 13px;
    margin-bottom: 2.25rem; }
    footer .footer-info .row {
      max-width: 1170px; }
    @media (max-width: 767.98px) {
      footer .footer-info .col-lg-6 {
        margin-bottom: 1.875rem; } }
    footer .footer-info .list-info {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      footer .footer-info .list-info li {
        position: relative;
        color: white;
        font-size: 13px;
        font-family: "Helvetica Neue";
        font-weight: 400; }
        footer .footer-info .list-info li + li {
          margin-top: 8px; }
    footer .footer-info .social-footer {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center; }
      footer .footer-info .social-footer li + li {
        margin-left: 11px; }
      footer .footer-info .social-footer li a {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid white;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        transition: .3s all  ease-in-out; }
        footer .footer-info .social-footer li a:hover {
          background-color: #fff; }
          footer .footer-info .social-footer li a:hover em {
            color: #a0081d; }
        footer .footer-info .social-footer li a em {
          color: white;
          transition: .3s all  ease-in-out; }
  footer .footer-copyright {
    color: white;
    font-size: 14px;
    border-top: solid 1px #ef2c29;
    text-align: center;
    color: #ffffff;
    padding: 10px; }

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: linear-gradient(to right, #a0081d, #e02622); }
  @media (max-width: 1023.98px) {
    header .container {
      max-width: 100%; } }
  header nav {
    height: 80px;
    position: relative;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 767.98px) {
      header nav {
        height: 60px; } }
    header nav .menu-right {
      height: 100%;
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center; }
      @media (max-width: 767.98px) {
        header nav .menu-right .btn {
          display: none; } }
      header nav .menu-right .btn-contact {
        margin-left: 20px; }
    header nav .menu-left {
      display: flex;
      height: 100%; }
      header nav .menu-left .logo {
        width: 186px;
        position: relative;
        z-index: 99; }
        @media (max-width: 767.98px) {
          header nav .menu-left .logo {
            width: 140px; } }
        header nav .menu-left .logo a {
          position: absolute;
          display: block;
          top: 0;
          width: 100%;
          left: 0; }
      @media (min-width: 1024px) {
        header nav .menu-left .list-menu {
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 28px; } }
      @media (max-width: 1023.98px) {
        header nav .menu-left .list-menu {
          position: fixed;
          top: 80px;
          right: 0;
          height: 100vh;
          background: linear-gradient(90deg, #a0081d, #e02622);
          width: 280px;
          padding: 0 15px;
          transform: translateX(100%);
          transition: .3s all ease-in-out;
          opacity: 0;
          will-change: transform; } }
  @media (max-width: 1023.98px) and (max-width: 575.98px) {
    header nav .menu-left .list-menu {
      width: 100%;
      padding-top: 60px; } }
      @media (max-width: 1023.98px) {
          header nav .menu-left .list-menu.isOpen {
            opacity: 1;
            transform: none; } }
      @media (max-width: 767.98px) {
        header nav .menu-left .list-menu {
          top: 60px; } }
      header nav .menu-left .list-menu ul {
        list-style-type: none;
        margin: 0;
        padding: 0; }
        @media (min-width: 1024px) {
          header nav .menu-left .list-menu ul {
            display: flex;
            align-items: center; } }
        @media (min-width: 1024px) {
          header nav .menu-left .list-menu ul li + li {
            margin-left: 36px; } }
        header nav .menu-left .list-menu ul li a {
          font-size: 0.875rem;
          font-weight: bold;
          text-transform: uppercase;
          padding-bottom: 5px;
          color: white;
          position: relative;
          transition: background-size .6s ease;
          background-image: linear-gradient(180deg, transparent 95%, white 0);
          background-repeat: no-repeat;
          background-size: 0 100%; }
          header nav .menu-left .list-menu ul li a:hover {
            background-size: 100% 100%; }
          @media (max-width: 1023.98px) {
            header nav .menu-left .list-menu ul li a {
              font-size: 1rem;
              padding: 15px 20px;
              display: block;
              border-bottom: 1px solid #ffffff40; } }
      header nav .menu-left .list-menu .btn {
        margin-top: 15px; }
